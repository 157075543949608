<template>
  <div class="newInventory">
    <PageHeader title="新增调拨" />
    <div class="flex j-between a-center p-lr-20 p-tb-20 SearchBox_30">
      <div class="flex" style="width:calc(100% - 550px)">
        <!--        <div class="flex a-center">-->
        <!--          <label class="f14 p-lr-5">调出仓库</label>-->
        <!--          <el-select v-model="form.OutStockCode" size="mini" placeholder="请选择">-->
        <!--            <el-option-->
        <!--              v-for="(item,index) in BuyersTerminal"-->
        <!--              :key="index"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value"-->
        <!--            />-->
        <!--          </el-select>-->
        <!--        </div>-->

        <!--        <div class="flex a-center">-->
        <!--          <label class="f14 p-lr-5">调入仓库</label>-->
        <!--          <el-select v-model="form.InStockCode" size="mini" placeholder="请选择">-->
        <!--            <el-option-->
        <!--              v-for="(item,index) in BuyersTerminal"-->
        <!--              :key="index"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value"-->
        <!--            />-->
        <!--          </el-select>-->
        <!--        </div>-->
        <div class="flex a-center">
          <label class="f14 p-lr-5" style="width:80px">单据备注</label>
          <el-input v-model="form.Remark" size="mini" />
        </div>
      </div>

      <div style="width:550px" class="flex j-end input_file">
        <el-button icon="el-icon-document-add" size="mini" round @click="import_">导入模板数据</el-button>
        <el-button icon="el-icon-download" size="mini" round @click="downdownload">下载导入模板</el-button>
        <input id="file" ref="filElem" type="file" name="file" multiple="multiple" @change="handleFileChange">
        <el-button style="margin-left:10px!important" round icon="el-icon-folder-add" size="mini" @click="showAddProduct">添加产品</el-button>
        <el-button round size="mini" @click="SaveInventoryTransferInfo(1)">保存草稿</el-button>
        <el-button type="primary" size="mini" round @click="SaveInventoryTransferInfo(2)">发起调拨</el-button>
      </div>
    </div>
    <div class="m-bottom-20 m-lr-20">
      <el-table
        id="testTable"
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="headerCStyle"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 380px)"
        row-key="id"
        :border="true"
      >
        <el-table-column align="center" prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column align="center" prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column align="center" prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column align="center" prop="MaterialTypeName" label="物料类型" show-overflow-tooltip />
        <el-table-column align="center" prop="Specification" label="规格" show-overflow-tooltip />
        <el-table-column align="center" prop="StockUnitN" label="单位" show-overflow-tooltip />
        <el-table-column align="center" prop="LotNum" label="批号/序列号" show-overflow-tooltip />
        <el-table-column align="center" prop="OUTStockName" label="调出仓库" show-overflow-tooltip />
        <el-table-column align="center" prop="InStockCode" label="调入仓库" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-select v-model="scope.row.InStockCode" size="mini" placeholder="请选择">
              <el-option
                v-for="(item,index) in BuyersTerminal"
                :key="index"
                :disabled="item.value === scope.row.OutStockCode"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LotNo" label="入库批次" show-overflow-tooltip />
        <el-table-column align="center" property="ExpiredDate" label="效期" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.setDateFormate(scope.row.ExpiredDate) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="StockNum" label="可调拨数量" show-overflow-tooltip />
        <el-table-column align="center" prop="InventoryNum" label="调拨数量" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input v-model="scope.row.InventoryNum" type="number" :max="scope.row.StockNum" :min="1" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DINum" label="UDI码" show-overflow-tooltip />
        <el-table-column align="center" prop="StorageConditionName" label="贮存条件" show-overflow-tooltip />
        <el-table-column width="50" align="center" prop="set" label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link size="mini" @click="delProduct(scope.$index,scope.row)">移除</el-link>
          </template>
        </el-table-column>
      </el-table>

    </div>
    <div class="flex j-between a-center m-lr-20">
      <div class="j-start">
        <span class="m-right-20">品种合计：{{ totas }}</span>
        <span>调拨数量：{{ nums }}</span>
      </div>
      <div class="pagination-container flex j-between  j-end">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      <!-- <el-button type="primary" icon="el-icon-printer" @click="print">打印</el-button> -->
      </div>
    </div>

    <el-dialog class="DepartmentBox" :close-on-click-modal="false" :visible.sync="visible" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">添加商品</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => visible = false" />
      </div>
      <div class="search searchView flex j-around m-tb-20">
        <div class="flex m-right-20" style="flex: 1">
          <el-input
            v-model="formSearch.KeyWords"
            size="medium"
            placeholder="可通过物料号、物料名称、仓库名称搜索"
            class="rule-input-edit"
          >
            <!-- <template slot="prepend">
              <div class="pointer" @click="() => visibl1e = true">
                <i class="el-icon-s-operation" />
                <span>高级搜索</span>
              </div>
            </template> -->
            <div
              slot="append"

              @click="searchAdADD"
            >
              <span class="cursor">搜 索</span>

            </div>
          </el-input>
        </div>
        <el-button style="font-size:14px" size="mini" icon="el-icon-folder-add" round @click="addGoodS">批量添加</el-button>
      </div>
      <el-table
        ref="tableDataProduct"
        :data="tableDataProduct"
        :header-cell-style="headerCStyle"
        border
        max-height="500px"
        @selection-change="isChnageP"
      >
        <!--   -->
        <el-table-column
          type="selection"
          width="40"
          :selectable="checkboxInit"
          fixed="left"
        />
        <!--   :selectable="checkboxInit" -->
        <el-table-column align="center" property="InvCode" label="物料编码" show-overflow-tooltip />
        <el-table-column align="center" property="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column align="center" property="BrandName" label="物料品牌" show-overflow-tooltip />
        <el-table-column align="center" property="MaterialTypeName" label="物料类别" show-overflow-tooltip />
        <el-table-column align="center" property="StockName" label="仓库" show-overflow-tooltip />
        <el-table-column align="center" property="StockName" label="是否盘点中" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.isCorrecting === 1 ? '是' :'否' }}
          </template>
        </el-table-column>
        <el-table-column align="center" property="Specification" label="物料规格" show-overflow-tooltip />
        <el-table-column align="center" property="StockUnitN" label="物料单位" show-overflow-tooltip />
        <el-table-column align="center" property="LotNum" label="物料批号" show-overflow-tooltip />
        <el-table-column align="center" property="ExpiredDate" label="效期" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.setDateFormate(scope.row.ExpiredDate) }}
          </template>
        </el-table-column>

        <el-table-column align="center" property="StockNum" label="可用数量" show-overflow-tooltip />
        <el-table-column align="center" property="a" fixed="right" label="操作" width="50">
          <template v-if="!scope.row.disabled && scope.row.isCorrecting !== 1 && scope.row.StockNum*1 >=1 " slot-scope="scope">
            <el-link :underline="false" @click="addGoodOne(scope.row)">添加</el-link>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container p-bottom-20">
        <el-pagination
          :ref="paginationName1"
          :total="tableTotalSize1"
          :pager-count="pagerCount1"
          :page-size="tablePageSize1"
          :background="tableBackground1"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange1($event)"
        />
      </div>
    </el-dialog>
    <!-- 修改备注 start -->
    <el-dialog :visible.sync="showSetName" custom-class="dialog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <p class="p-left-20 weight">输入调拨名称</p>
        <p class="p-right-10 weight f16 cursor" style="color:#000" @click="() => showSetName = false">x</p>
      </div>
      <el-input
        v-model="form.InventoryName"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 8}"
        placeholder="请输入内容"
      />
      <div class="p-top-20 min-border-top flex a-center j-center">
        <el-button size="mini" round @click="() => showSetName = false">取消</el-button>
        <el-button size="mini" type="primary" round @click="SaveStockCorrectInfo">确定</el-button>
      </div>
    </el-dialog>
    <!-- 修改备注 end -->
  </div>
</template>

<script>
import Index from '@/minxin/management/newInventory'
export default Index
</script>
