import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
const Index = {
  name: 'newInventory',
  mixins: [tableMixin],
  components: {
    PageHeader
  },
  computed: {
    totas() {
      return this.tableData.length
    },
    nums() {
      let num = 0
      this.tableData.map(item => {
        if (item.InventoryNum) {
          num += item.InventoryNum * 1
        }
      })
      return num
    }
  },
  watch: {
    tableData(a) {
      // a.map(item => {
      //   this.$set(item, 'OUTStockName', item.StockName)
      //   this.$set(item, 'OutStockCode', item.StockCode)
      // })
      // console.log(a)
    }
  },
  data() {
    return {
      showSetName: false,
      paginationName1: 'tablePagination1',
      tableTotalSize1: 0,
      pagerCount1: 5,
      tablePageSize1: 10,
      tableBackground1: true,
      visible: false,
      form: {
        InStockCode: '',
        OutStockCode: '',
        Remark: '',
        lst: [],
        IDX: 0,
        Status: 0 // 1 保存 草稿 2 发起调拨
      },
      formSearch: {
        KeyWords: '',
        InvCode: '',
        InvName: '',
        PageSize: 10,
        PageIndex: 1
      },
      tableDataProduct: [],
      BuyersTerminal: [],
      tempArrP: [],
      IDX: ''
    }
  },
  mounted() {
    this.QueryInStockList()
    // console.log(this.$route.params)
    // console.log(this.$store.state.index.cachedViews)
    this.IDX = this.$route.params.IDX
    if (this.$route.params.IDX !== '0' && this.$route.params.IDX !== 0) {
      this.getDetail()
    }
  },
  methods: {
    // 调拨名称
    SaveStockCorrectInfo() {
      this.$api.SaveInventoryTransferInfo(this.form).then(res => {
        this.showSetName = false
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$router.push({ name: 'InventoryTransfer', params: { isCache: 6 }})
        const IndexAitem = this.$store.state.index.cachedViews.findIndex(item1 => item1 === 'newInventory')
        this.$store.state.index.cachedViews.splice(IndexAitem, 1)
        this.$store.dispatch('index/setCachedViews', this.$store.state.index.cachedViews)
        return this.$message.success('操作成功')
      })
    },
    async handleCurrentChange1(page) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize1
      const { Message, Total, RetCode, RetMsg } = await this.GetSellCompanyMateialStockList(this.formSearch)
      if (RetCode !== '0') return this.$message.error('错误：' + RetMsg)
      if (!Array.isArray(Message)) {
        this.tableTotalSize1 = Total
        this.tableDataProduct = []
        return this.$message.warning('没查到数据')
      }
      if (RetCode === '0' && Message.length >= 0) {
        console.log(Message)
        this.tableTotalSize1 = Total
        this.tableDataProduct = Message
        this.tableDataProduct.map(item => {
          if (item.isCorrecting === 1) {
            return this.$set(item, 'disabled', true)
          }
          return this.$set(item, 'disabled', false)
        })
        this.checkProduceIs()
        this.tableDataProduct.map(item => {
          if (item.isCorrecting === 1) {
            return this.$set(item, 'disabled', true)
          }
        })
      }
    },
    async getTableDataAsync(page, pageSize) {
      // const response = await this.$api.QueryStockDeliveryList(this.formSearch)
      // this.tableData = response.Message
      // this.tableTotalSize = response.Total
      return { Message: [], Total: 0 }
    },
    // 获取未完成的调拨明细
    async  getDetail() {
      const response = await this.$api.GetInventoryTransferInfo({
        IDX: this.IDX
      })
      console.log(response)
      if (!response.Message) return
      this.form.OutStockCode = response.Message.OutStockCode
      this.form.InStockCode = response.Message.InStockCode
      this.form.Remark = response.Message.Remark
      this.tableData = response.Message.lst
      this.tableData.map(item => {
        this.$set(item, 'isOld', true)
      })
      this.form.IDX = response.Message.IDX
      // this.infoData = response.Message
    },
    showAddProduct() {
      this.handleCurrentChange1()
      this.visible = true
    },
    checkboxInit(row, index) {
      if (row.disabled || row.StockNum * 1 <= 0) {
        return 0// 不可勾选
      } else {
        return 1// 可勾选
      }
    },
    // 下载模板
    downdownload() {
      window.open(this.b2bstaticUrl + '/WebUpload/download/导入库存调拨明细模板.xlsx')
    },
    handleFileChange(e) {
      const file = e.target.files[0]
      // console.log('afda', file)
      // const size = Math.floor(file.size / 1024 / 1024)
      // if (size > 5) {
      //   return this.$message({
      //     message: '图片大小不能超过5M',
      //     type: 'warning'
      //   })
      // }
      var reader = new FileReader()
      //   const url = ''
      reader.readAsDataURL(file)
      const this_ = this
      // this.form.FileExtName = '.' + file.type.split('/')[1]
      const FileExtName = file.name.split('.')[file.name.split('.').length - 1]
      reader.onload = function() {
        // encodeURIComponent(e.target.result)
        console.log(this.result)
        this_.InventoryTransfer_Import(this.result, FileExtName)
      }
    },
    import_() {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    },
    // 导入
    InventoryTransfer_Import(FileData, FileExtName) {
      // 处理表格数据
      const obj = {
        FileData: FileData,
        FileType: FileExtName
      }
      this.$api.InventoryTransfer_Import(obj).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$message.success('导入成功')
        this.tableData = this.tableData.concat(res.Message)
        return this.$refs.filElem.value = null
      })
    },
    // 处理表格数据
    setTableData(type) {
      this.form.lst = []
      // if (!this.form.IDX) {
      // 新增
      this.tableData.map(item => {
        if (item.isOld) {
          this.form.lst.push({
            IDX: item.IDX,
            materialRefCompanyID: item.materialRefCompanyID, // 物料id
            StockSellID: item.StockSellID, // 库存id
            LotNum: item.LotNum,
            LotNo: item.LotNo,
            ExpiredDate: item.ExpiredDate,
            InStockCode: item.InStockCode,
            InventoryNum: item.InventoryNum * 1// 调拨数量item
          })
          return
        }
        this.form.lst.push({
          IDX: 0,
          materialRefCompanyID: item.IDX, // 物料id
          StockSellID: item.StockSellID, // 库存id
          LotNum: item.LotNum,
          LotNo: item.LotNo,
          InStockCode: item.InStockCode,
          ExpiredDate: item.ExpiredDate,
          InventoryNum: item.InventoryNum * 1 // 调拨数量
        })
      })
      // }
      // if (this.form.IDX) {
      //   // 之前已保存的数据
      //   this.tableData.map(item => {
      //     this.form.lst.push({
      //       IDX: item.IDX,
      //       materialRefCompanyID: item.materialRefCompanyID, // 物料id
      //       StockSellID: item.StockSellID, // 库存id
      //       LotNum: item.LotNum,
      //       LotNo: item.LotNo,
      //       ExpiredDate: item.ExpiredDate,
      //       InventoryNum: item.InventoryNum // 调拨数量
      //     })
      //   })
      //   return
      // }
    },
    // 发起保存调拨
    SaveInventoryTransferInfo(type) {
      this.form.Status = type
      // this.form.lst = this.tableData
      console.log(this.form)
      // if (!this.form.OutStockCode) return this.$message.error('请选择调出仓库')
      // if (!this.form.InStockCode) return this.$message.error('请选择调入仓库')
      if (this.tableData.length <= 0) return this.$message.warning('请选择调拨的物料')
      const arr = this.tableData.filter(item => item.InventoryNum)
      if (arr.length <= 0) return this.$message.warning('请选择调拨数量')
      const brr = this.tableData.filter(item => item.InStockCode)
      if (brr.length <= 0) return this.$message.warning('请选择仓库')
      this.setTableData(type)
      this.showSetName = true
      console.log(this.form)
    },
    // 检测物料是否已被选择
    checkProduceIs() {
      this.tableData.map(item => {
        this.tableDataProduct.map(item1 => {
          this.$set(item1, 'disabled', false)
          if (item.StockSellID === item1.StockSellID) {
            this.$nextTick(() => {
              this.$refs.tableDataProduct.toggleRowSelection(item1, true)
              this.$set(item1, 'disabled', true)
            })
          }
        })
      })
    },
    // 添加商品列表
    GetSellCompanyMateialStockList() {
      return this.$api.GetSellCompanyMateialStockList(this.formSearch)
    },
    // 添加商品 搜索
    searchAdADD() {
      this.handleCurrentChange1()
    },
    isChnageP(e) {
      this.tempArrP = e
    },
    // 批量添加
    addGoodS() {
      const result = this.tempArrP.filter(item => item.disabled === false)
      if (result.length <= 0) return this.$message.warning('请选择你要添加的物料')
      result.map(item => {
        this.$set(item, 'OUTStockName', item.StockName)
        this.$set(item, 'OutStockCode', item.StockCode)
      })
      this.tableData = this.tableData.concat(result)
      this.visible = false
    },
    // 添加一个
    addGoodOne(row) {
      this.$set(row, 'OUTStockName', row.StockName)
      this.$set(row, 'OutStockCode', row.StockCode)
      this.tableData.push(row)
      this.$refs.tableDataProduct.toggleRowSelection(row, true)
      this.$set(row, 'disabled', true)
      // this.tableData.map(item => {
      //   this.$set(item, 'OUTStockName', item.StockName)
      //   this.$set(item, 'OutStockCode', item.StockCode)
      // })
    },
    // 移除物料
    delProduct(index, row) {
      this.tableData.splice(index, 1)
    },
    // 入库仓库 QueryInStockList
    QueryInStockList() {
      this.$api.QueryInStockList().then(res => {
        this.BuyersTerminal = res.Message
      })
    }
  }
}
export default Index
